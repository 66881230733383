import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { supportStore } from '../stores';
import { Data, IContactFormRequest, IControllerResponseDTO, IFaqMainDTO } from '@/interfaces';

const getSubscriptionPlans = async (): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: '/subscriptions/plans',
    method: 'get',
  });

  if (status === constant.Success) {
    const { faqs } = data as IFaqMainDTO;
    supportStore.setFaqs(faqs);
    return { status };
  }
  return { status, errorMessage };
};





const SubscriptionController = {
  getSubscriptionPlans
};

export default SubscriptionController;
