import { IDefaultObject } from '@/interfaces';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const amountFormatter = (currency: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'NGN',
  });

export const phoneRegExp = /^(\+?[0-9] ?(?:[0-9] ?){5,13}[0-9]|\d{11})$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\])[A-Za-z\d!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\]+$/;

export const accountNoRegex = /^\d{10}$/;

export const removeEmptyKeys = (payload: IDefaultObject) => {
  for (const key in payload) {
    if (payload[key] && typeof payload[key] === 'object') {
      removeEmptyKeys(payload[key]);

      if (Object.keys(payload[key]).length === 0) {
        delete payload[key];
      }
    } else if (payload[key] === undefined || payload[key] === null || payload[key] === '') {
      delete payload[key];
    }
  }
};



