import styles from '@/assets/styles/pages/home/Home.module.scss';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useSwitchNav } from '@/components/PageLayout/_PageLayoutComponents/SwitchNav';
import { MimaText, FashButton } from '@/components';
import { FaArrowRight } from 'react-icons/fa6';

const currencies = ['USD', 'EUR', 'GBP', 'NGN', 'EGP', 'ZAR', 'GHS', 'ZAR', 'CFA']; // Add more currencies as needed

const Hero = () => {
  const router = useRouter();
  const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);
  const [typedText, setTypedText] = useState('');
  const [isTyping, setIsTyping] = useState(true); // Tracks whether typing or deleting
  const [charIndex, setCharIndex] = useState(0);
  const [currencyIndex, setCurrencyIndex] = useState(0);
  const { switchToSignup } = useSwitchNav();

  // Function to handle typing effect
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isTyping) {
        // Typing phase: add the next character
        if (charIndex < currentCurrency.length) {
          setTypedText((prevText) => prevText + currentCurrency[charIndex]);
          setCharIndex((prevIndex) => prevIndex + 1);
        } else {
          // Start the deleting phase once typing is done
          setIsTyping(false);
        }
      } else {
        // Deleting phase: remove the last character
        if (charIndex > 0) {
          setTypedText((prevText) => prevText.slice(0, -1));
          setCharIndex((prevIndex) => prevIndex - 1);
        } else {
          // Once fully deleted, switch to the next currency
          const nextIndex = (currencyIndex + 1) % currencies.length;
          setCurrencyIndex(nextIndex);
          setCurrentCurrency(currencies[nextIndex]);
          setIsTyping(true); // Start typing the new currency
        }
      }
    }, isTyping ? 200 : 100); // Typing speed: 100ms, Deleting speed: 50ms

    return () => clearTimeout(timeout);
  }, [charIndex, currentCurrency, isTyping, currencyIndex]);

  return (
    <section className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.text}>
          <MimaText variant='subtitle' color='var(--color-white)'>
            Ready to Grow Your Fashion Business?
          </MimaText>
          <MimaText variant='h1' color='var(--color-white)'>
            Create an Online Store and Start Selling in <span>{typedText}</span> Today.
          </MimaText>
        </div>
        <div className={styles.buttonSet}>
          <FashButton title="Start A Free Trial Now" icon={<FaArrowRight/>} color='var(--color-primary-2)' onClick={switchToSignup} />
          <MimaText variant='small' color='var(--color-white)'>
            7 days free, then 1 month free<br />upon sign up.
          </MimaText>
        </div>
      </div>
    </section>
  );
};

export default Hero;
