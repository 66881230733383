import React, { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import styles from '@/assets/styles/pages/home/Home.module.scss';
import { SliderImg3, SliderImg5, SliderImg6 } from '@/assets/img/ImgList';
import Image from 'next/image';
import { MimaText } from '@/components';

export const slides = [
  {
    imgUrl: SliderImg5,
    testimony:
      'As a premium fash creatives user, I particularly love the fact that I am able to delegate certain parts Critical operations such as Financial Control and Customer Service to my staff with the privilege of Limiting access to these rights.',
    name: 'Zainab Oduniyi',
    business: 'Toshowoods',
    title: 'Simple for operations',
  },
  {
    imgUrl: SliderImg3,
    testimony:
      'Using fash creatives has been one of the best things I did for my business this year. The business account, invoice and receipt feature is my favorite because it makes my brand more professional and serious. I can even do a money transfer through the app…..mind blowing!!',
    name: 'Ohen Michael',
    business: 'CEO St. Michael beauty',
    title: 'Simplified Business Accounting',
  },
  {
    imgUrl: SliderImg6,
    testimony:
      "I recently downloaded the Fash creatives app and it's exceeding my expectations. It's swift, easy to use, and I love the feature that allows me to create styles and share my unique web link for clients to request styles. It's a game-changer for managing clients and sales. Solid 8/10!",
    name: 'Olaitan',
    business: 'CEO of Phase Designs',
    title: 'Easy to Use',
  },
  {
    imgUrl: SliderImg3,
    testimony:
      'Using fash creatives has been one of the best things I did for my business this year. The business account, invoice and receipt feature is my favorite because it makes my brand more professional and serious. I can even do a money transfer through the app…..mind blowing!!',
    name: 'Ohen Michael',
    business: 'CEO St. Michael beauty',
    title: 'Simplified Business Accounting',
  },
  {
    imgUrl: SliderImg6,
    testimony:
      "I recently downloaded the Fash creatives app and it's exceeding my expectations. It's swift, easy to use, and I love the feature that allows me to create styles and share my unique web link for clients to request styles. It's a game-changer for managing clients and sales. Solid 8/10!",
    name: 'Olaitan',
    business: 'CEO of Phase Designs',
    title: 'Easy to Use',
  },
];

const Testimonials = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

useEffect(() => {
  if (!emblaApi) return;

  const onSelect = () => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  };

  emblaApi.on('select', onSelect);
  onSelect(); // Set the initial selected index

  return () => {
    emblaApi.off('select', onSelect);
  }; // Return a void function
}, [emblaApi]);


  return (
    <section className={styles.testimonials} id="testimonial">
      <div className={styles.container}>
        <div className={styles.title}>
          <MimaText variant='h2'>Our customers say it best</MimaText>
          <MimaText variant="body">Don&apos;t just take our word for it, hear from our satisfied customers</MimaText>
          </div>

        <div className={styles.embla} ref={emblaRef}>
          <div className={styles.embla__container}>
            {slides.map((slide, index) => (
              <div className={styles.embla__slide} key={index}>
                <p className={styles.testimony} >{slide.testimony}</p>
                <div className={styles.user}>
                  <Image
                    src={slide.imgUrl}
                    alt="Testimonial"
                    width={50}
                    height={50}
                  />
                  <MimaText variant="body">{slide.name}</MimaText>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Render the indicators */}
        <div className={styles.indicators}>
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`${styles.indicator} ${
                index === selectedIndex ? styles.isActive : ''
              }`}
              onClick={() => {if (emblaApi) {
                emblaApi.scrollTo(index);}}}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
