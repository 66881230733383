import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { userStore } from '../stores';
import { Data, IControllerResponseDTO, IDefaultObject, LoginRequest } from '@/interfaces';

const login = async (payload: LoginRequest): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: '/users/user/login',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const { token, user } = (data || {}) as Data;
    userStore.setUser(user);
    // localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('fashUserLogin', JSON.stringify(true));
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    return { status };
  }

  return { status, errorMessage };
};

const signup = async (payload: IDefaultObject): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: '/businesses/business',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const { userId } = (data || {}) as Data;
    userStore.setUserId(userId);
    return { status };
  }

  return { status, errorMessage };
};

const activateUser = async (token: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: `/users/user/activate/${userStore.userId}/${token}/user`,
    method: 'get',
  });
  if (status === constant.Success) {
    const { token, user } = (data || {}) as Data;

    userStore.setUser(user);
    // localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('fashUserLogin', JSON.stringify(true));
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const UserController = {
  login,
  signup,
  activateUser,
};

export default UserController;
