import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { partnerStore, userStore } from '../stores';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  Data,
  IAddAccount,
  IControllerResponseDTO,
  IMarketer,
  IMarketerWithAccount,
  IPartnerAnalysis,
  IPartnerBanksDTO,
  IPartnerLogin,
  IPartnerLoginDTO,
  IPartnerLoginFailedDTO,
  IPartnerSignup,
  IPartnerSignupDTO,
  IVerifyBank,
} from '@/interfaces';

const login = async (payload: IPartnerLogin): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: '/marketers/marketer/login',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const { token, marketer } = (data || {}) as Data;
    // localStorage.setItem('fashPartnerLogin', JSON.stringify(true));
    // localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('marketerInfo', JSON.stringify(token));
    partnerStore.setMarketer(marketer);
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    if (marketer?.bankAccount) {
      partnerStore.setMarketerWithBank(marketer?.bankAccount);
    }
    await getAnalysis();
    return { status };
  }

  const { marketerId } = (data || {}) as IPartnerLoginFailedDTO;
  partnerStore.setMarketerId(marketerId);

  return { status, data, errorMessage };
};

const addWithdrawalAccount = async (payload: IAddAccount): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: '/marketers/marketer/withdrawal',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const dataReturned = data as IMarketerWithAccount;
    partnerStore.setMarketerWithBank(dataReturned);
    return { status };
  }
  return { status, errorMessage };
};

const signup = async (payload: IPartnerSignup): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: '/marketers/marketer',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const { marketerId } = (data || {}) as IPartnerSignupDTO;
    partnerStore.setMarketerId(marketerId);
    return { status, data };
  }
  return { status, errorMessage };
};

const validateReferalCode = async (code: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: `/marketers/validate-referral/${code}`,
    method: 'get',
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const forgetPasswordValidate = async (emailMobile: string, token: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: `/marketers/validate/${emailMobile}/${token}`,
    method: 'get',
  });

  if (status === constant.Success) {
    const { marketer, token } = (data || {}) as IPartnerLoginDTO;
    partnerStore.setMarketer(marketer);
    // localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('fashPartnerLogin', JSON.stringify(true));
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const activatePartner = async (marketerId: string, token: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: `/marketers/marketer/activate/${marketerId}/${token}`,
    method: 'get',
  });

  if (status === constant.Success) {
    const { marketer, token } = (data || {}) as IPartnerLoginDTO;
    partnerStore.setMarketer(marketer);
    // localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('fashPartnerLogin', JSON.stringify(true));
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const resendCode = async (marketerId: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: `/marketers/marketer/resend/${marketerId}`,
    method: 'get',
  });

  if (status === constant.Success) {
    return { status, data };
  }
  return { status, errorMessage };
};

const forgotPassword = async (emailMobile: string): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: `/marketers/forget-password/${emailMobile}`,
    method: 'get',
  });

  if (status === constant.Success) {
    return { status, data };
  }
  return { status, errorMessage };
};

const banks = async (): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: '/banking/banks',
    method: 'get',
  });
  if (status === constant.Success) {
    const { banks } = data as IPartnerBanksDTO;
    const bankList = banks.map((bank) => {
      return { key: bank.bankCode, value: bank.bankName };
    });
    partnerStore.setBanks(bankList);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getAnalysis = async (): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: '/marketers/analysis',
    method: 'get',
  });

  if (status === constant.Success) {
    const analysis = data as IPartnerAnalysis;
    partnerStore.setAnalysis(analysis);
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

const verifyAccountNumber = async (payload: IVerifyBank): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: '/banking/bank/verify-account-details',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

const resetPassword = async (payload: any): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: `/marketers/marketer/change-password`,
    method: 'patch',
    payload,
  });

  if (status === constant.Success) {
    return { status, data };
  }
  return { status, errorMessage };
};

const customizeRefCode = async (payload: any): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: `/marketers/set-marketer-custom-referral-code`,
    method: 'patch',
    payload,
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status, data };
  }
  return { status, errorMessage };
};

const resetForgotPassword = async (payload: any): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: `/marketers/reset-password`,
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    return { status, data };
  }
  return { status, errorMessage };
};

const useGetMarketerBiz = (marketerTableQuery: string, filterQuery: string, searchQuery: string) => {
  const { marketer } = partnerStore as {
    marketer: IMarketer;
  };
  const id = marketer._id;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${marketerTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${marketerTableQuery}&${filterQuery}`;
    } else {
      return marketerTableQuery;
    }
  }, [filterQuery, marketerTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, 'getMarketerBiz', query],
    queryFn: () => {
      return apiRequest({
        url: `/marketers/commission?${query}`,
        method: 'get',
      });
    },
  });
};

const PartnerController = {
  login,
  signup,
  activatePartner,
  resendCode,
  forgetPasswordValidate,
  banks,
  addWithdrawalAccount,
  getAnalysis,
  verifyAccountNumber,
  resetPassword,
  useGetMarketerBiz,
  forgotPassword,
  resetForgotPassword,
  validateReferalCode,
  customizeRefCode,
};

export default PartnerController;
