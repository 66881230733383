'use client';
import React, { useEffect, useMemo, useState } from 'react';
import { HowTo, Pricing, Hero, Testimonials, Closer, Benefit } from './_homeComponents';
import { BusinessController } from '@/controllers';
import { useGeoLocation } from '@/hooks';
import { PageLayout1 } from '@/components';

const Home = () => {
  const { country } = useGeoLocation();

  const planQuery = useMemo(() => {
    // Only return a plan query if country is defined
    return country ? `countryCode=${country}&app=FASH` : undefined;
  }, [country]);

useEffect(() => {
  if (planQuery) {
    const fetchPlans = async () => {
      const result = await BusinessController.getAllSubscriptions(planQuery);
    };
    fetchPlans();
  }
}, [planQuery]);


  return (
    <PageLayout1>
      <Hero />
      <HowTo />
      <Benefit />
      <Testimonials />
      <Pricing />
      <Closer />
    </PageLayout1>
  );
};

export default Home;
