import axios, { InternalAxiosRequestConfig } from 'axios';
import Config from '@/utils/config';
import { Data } from '@/interfaces';
import { userStore } from '@/stores';

const client = axios.create();

const exceptionEndpoint = [`${Config.BASE_URL}/auth/login`, `${Config.BASE_URL}/partnership/login`];

// const token: string = JSON.parse(localStorage.getItem('token') || '');

client.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<Data>) => {
    if (!exceptionEndpoint.includes(String(config.url))) {
      config.headers.Authorization = `Bearer ${userStore.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error?.response || {};

    if (status === 401) {
      // Log user out
      userStore.setLoggedIn(false);
      // localStorage.setItem('fashPartnerLogin', JSON.stringify(false));
      // localStorage.setItem('fashUserLogin', JSON.stringify(false));
      delete client.defaults.headers.common.Authorization;
    }

    return Promise.reject(error);
  },
);

export default client;
