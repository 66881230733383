import constant from '@/utils/constant';
import client from './client';
import config from '@/utils/config';
import { Data, IErrorMessageDTO, IApiResponseDTO, Response } from '@/interfaces';

type axiosMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';
interface Props {
  baseUrl?: string;
  url: string;
  method?: axiosMethod;
  hasImageUpload?: boolean;
  hasImageDownload?: boolean;
  payload?: Data;
  headers?: Data;
  onUploadProgress?: (progressEvent: any) => void;
  onDownloadProgress?: (progressEvent: any) => void;
}

const apiRequest = async <T>({
  baseUrl = config.BASE_API_URL,
  url,
  method = 'get',
  payload,
  hasImageUpload,
  hasImageDownload,
  onUploadProgress,
  onDownloadProgress,
}: Props): Promise<Response<T | IErrorMessageDTO>> => {
  try {
    let headers = {};
    if (hasImageUpload) {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
    }
    if (hasImageDownload) {
      headers = {
        responseType: 'blob',
      };
    }
    const { data } = await client[method]<IApiResponseDTO<T>>(baseUrl + url, payload, {
      headers,
      timeout: 30000,
      onUploadProgress: onUploadProgress,
      onDownloadProgress: onDownloadProgress,
    });
    return { status: constant.Success, data: data as T };
  } catch (error: any) {
    const { data, status } = (error.response || {}) as IApiResponseDTO<IErrorMessageDTO>;
    return {
      status: constant.Failed,
      errorMessage: data?.message || data?.error || 'Network error',
      data: data,
      errorCode: status,
    };
  }
};

export { apiRequest };
