import Benefit1 from './Benefit 1.png'
import Benefit2 from './Benefit 2.png'
import Benefit3 from './Benefit 3.png'
import Benefit4 from './Benefit 4.png'
import Benefit5 from './Benefit 5.png'


export {
  Benefit1,
  Benefit2,
  Benefit3,
  Benefit4,
  Benefit5,
};



