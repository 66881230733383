import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { supportStore } from '../stores';
import { Data, IContactFormRequest, IControllerResponseDTO, IFaqMainDTO } from '@/interfaces';

const faqCategories = async (): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: '/supports/faq/categories',
    method: 'get',
  });

  if (status === constant.Success) {
    const { faqs } = data as IFaqMainDTO;
    supportStore.setFaqs(faqs);
    return { status };
  }
  return { status, errorMessage };
};

const contactDetails = async (): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: '/supports/contact-details',
    method: 'get',
  });
  if (status === constant.Success) {
    supportStore.setContactDetails(data);
    return { status };
  }
  return { status, errorMessage };
};
const contactUs = async (payload: IContactFormRequest): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: '/supports/contact-us',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    return { status };
  }
  return { status, errorMessage };
};

const createSupport = async (payload: any): Promise<IControllerResponseDTO> => await apiRequest<Data>({ url: '/supports', method: 'post', payload });

const SupportController = {
  createSupport,
  faqCategories,
  contactDetails,
  contactUs,
};

export default SupportController;
