import { useState, useEffect, useRef } from 'react';
import styles from '@/assets/styles/pages/home/Home.module.scss';
import { MimaButton, MimaText } from '@/components';
import { useRouter } from 'next/navigation';
import { observer } from 'mobx-react-lite';
import { businessStore } from '@/stores';
import { amountFormatter } from '@/utils/utils';
import { billingOptions,BillingOptionProps} from '@/utils/billingOptions';
import { BouncySquareLoader } from '@/assets/img/ImgList';
import Image from 'next/image';
import { Plan } from '@/interfaces';
import { useSwitchNav } from '@/components/PageLayout/_PageLayoutComponents/SwitchNav';




const Pricing = observer(() => {
  const [selectedBilling, setSelectedBilling] = useState<BillingOptionProps>({
    name: "annual",
    billingSubText: "annually",
    multiplier: 12,
    discounter: "oneYearDiscountPer"
  });
  const [loading, setLoading] = useState(true);
  
  const { switchToSignup } = useSwitchNav();
  
  const { subscriptionPlans } = businessStore;
  const plans: Plan[] = subscriptionPlans as Plan[];
  function getOrderedPlans(plans: Plan[]): Plan[] {
    // Step 1: Filter out duplicates (only keep the first occurrence of each plan name)
    const uniquePlans: Plan[] = [];
    const seenNames = new Set<string>();

    for (const plan of plans) {
      if (!seenNames.has(plan.name)) {
        uniquePlans.push(plan);
        seenNames.add(plan.name);
      }
    }

    const order = ['Basic', 'Premium', 'Enterprise'];

    uniquePlans.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
    return uniquePlans;
  }

  const fashSubs = getOrderedPlans(plans);

  useEffect(() => {
    // Check if subscriptionPlans have loaded
    if (subscriptionPlans.length === 0) {
      setLoading(true); // Plans are still loading
    } else {
      setLoading(false); // Plans have loaded
    }
  }, [subscriptionPlans]);

  const [fade, setFade] = useState('fade-in');

  useEffect(() => {
    setFade('fade-out');
    const timer = setTimeout(() => {
      setFade('fade-in');
    }, 300); // Duration should match the CSS transition duration

    return () => clearTimeout(timer);
  }, [selectedBilling]);


  return (
    <section className={styles.pricing} id='pricing'>
      <div className={styles.container}>
        <div className={styles.title}>
          <MimaText variant='h2'> Plan and Pricing</MimaText>
          <MimaText variant='body'>Select the perfect plan for your fashion business needs</MimaText>
        </div>
        <div className={styles.billingSelector}>
          {billingOptions.map((option, index) => (
            <button
              key={index}
              className={`${styles.billingOption} ${selectedBilling.name === option.name ? styles.selected : ''}`}
              onClick={() => setSelectedBilling(option)}
            >
              {option.name.charAt(0).toUpperCase() + option.name.slice(1)} billing
            </button>
          ))}
        </div>
        {loading ? <Image src={BouncySquareLoader} alt='loading' /> : (
          <div className={styles.pricingCardList}>
            {fashSubs.map((plan) => (
              <div
                className={`${styles.pricingCard} ${plan.name === 'Premium' ? styles.recommended : ''}`}
                key={plan.name}>

                <div className={styles.top}>
                  <div className={styles.textArea}>
                    <MimaText variant='subtitle' fontWeight='600' className={styles.pricingPlan}>{plan.name}</MimaText>
                    <div className={styles.prominent}>
                      <p className={styles.price}>
                        {selectedBilling.discounter ? amountFormatter(plan.currencyCode).format(plan.amount * (100 - (plan[selectedBilling.discounter as keyof Plan] as number)) / 100): amountFormatter(plan.currencyCode).format(plan.amount)}
                      </p>
                      <p className={styles.priceSubtext}>every month</p>
                    </div>
                    <MimaText variant='body' className={`${styles.subtext} ${styles[fade]}`}>
                      {selectedBilling.discounter ? (
                        <>
                          {`${amountFormatter(plan.currencyCode).format(
                            // Ensure the value of plan[selectedBilling.discounter] is a number before doing arithmetic
                            typeof plan[selectedBilling.discounter as keyof Plan] === 'number'
                              ? plan.amount *
                              selectedBilling.multiplier *
                              (100 - (plan[selectedBilling.discounter as keyof Plan] as number)) / 100
                              : plan.amount * selectedBilling.multiplier
                          )} billed ${selectedBilling.billingSubText}.`}
                          <span>
                            {` Save ${plan[selectedBilling.discounter as keyof Plan]}%`}
                          </span>
                        </>
                      ) : (
                        <>
                          {`${amountFormatter(plan.currencyCode).format(
                            plan.amount * selectedBilling.multiplier
                          )} billed ${selectedBilling.billingSubText}`}
                        </>
                      )}
                    </MimaText>

                  </div>
                  <MimaButton
                    variant="fullWidth"
                    title="Choose Plan"
                    buttonColor={plan.name === 'Premium' ? 'var(--color-primary)' : undefined}
                    onClick={switchToSignup}
                  />

                </div>
                <hr />
                <div className={styles.featureList}>
                  {plan.coverage.map((feature, index) => (

                    <MimaText variant='small' key={index}>{feature}</MimaText>

                  ))}
                </div>

              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
});

export default Pricing;
