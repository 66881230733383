import styles from '@/assets/styles/pages/home/Home.module.scss';
import Image from 'next/image';
import { MimaText } from '@/components';
import constant from '@/utils/constant';
import Tailor from '@/assets/img/home/Tailor no background.png';
import { FC, useRef } from 'react';
import { useInView } from 'framer-motion';
import { FaApple, FaGooglePlay } from 'react-icons/fa6';





export interface CloserProps {
    heading?: string;
    body?: string
}

export const DownloadAppBtns = () => {


    return (
        <div className={styles.dl}>
            <a href={constant.Routes.AndroidApp} className={styles.dlButton}>
                <FaGooglePlay size={25} />
                <div className={styles.textArea}>
                    <MimaText variant='xsmall'>GET IT ON</MimaText>
                    <MimaText variant='body'>Google Play</MimaText>
                </div>
            </a>
            <a href={constant.Routes.IosApp} className={styles.dlButton}>
                <FaApple size={25} />
                <div className={styles.textArea}>
                    <MimaText variant='xsmall'>GET IT ON</MimaText>
                    <MimaText variant='body'>App Store</MimaText>
                </div>
            </a>
        </div>
    );
};
const Closer: FC<CloserProps> = ({
    heading = 'Unlock Your Business’ Full Potential',
    body = 'Expand and Go Global with a seamless solutions channel',
}) => {

    const frameRef = useRef(null);
    const isFrameInView = useInView(frameRef, { once: true, amount: .7 });

    return (
        <section className={styles.closer}>
            <div className={styles.container}>
                <div className={`${styles.frame} ${isFrameInView ? styles.fadeIn : ''}`} ref={frameRef}>
                    <div className={styles.textArea}>
                        <div className={styles.title}>
                            <MimaText variant='h3'>
                                {heading}
                            </MimaText>
                            <MimaText variant="body">
                                {body}
                            </MimaText>
                        </div>
                        <DownloadAppBtns />
                    </div>
                    <Image
                        src={Tailor}
                        alt="Fashion designer "
                        className={styles.overflow}
                    />
                </div>
            </div>
        </section>
    )
}
export default Closer