import styles from '@/assets/styles/pages/home/Home.module.scss';
import React, { useRef } from 'react';
import { HowToImage } from '@/assets/img/ImgList';
import Image from 'next/image';
import FashButton from '@/components/FashButton';
import { MimaText, MimaButton } from '@/components';
import { useSwitchNav } from '@/components/PageLayout/_PageLayoutComponents/SwitchNav';
import { useInView } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa6';



const HowTo = () => {
  const { switchToLogin, switchToSignup } = useSwitchNav()
  const imageRef = useRef(null);
  const imageIsInView = useInView(imageRef, { once: true, amount: .5 })

  const steps = [
    {
      step: 1,
      title: 'Download APP',
      details: 'Download the app “FashCreatives” on either your iOS App Store or Android Google Play Store.'
    },
    {
      step: 2,
      title: 'Create your free online store',
      details: 'Register, verify your account and create your FREE Website, Domain name and Configure your website to your preference'
    },
    {
      step: 3,
      title: 'Add your product and prices',
      details: 'Upload your products, set prices, and customize product descriptions. Ensure your inventory is ready for customers to browse and purchase.'
    },
    {
      step: 4,
      title: 'Share your website and start selling',
      details: 'Register, verify your account and create your FREE Website, Domain name and Configure your website to your preference'
    },
  ];

  return (
    <section className={styles.howTo}>
      <div className={styles.container}>
        <MimaText variant='h2' align='center' className={styles.titleText} >
          When You Use <span>Fash</span> Creatives, You Make More Money
        </MimaText>
        <MimaText variant="body" align='center'>
          Fash Creatives provides you with various effective tools to
          seamlessly run your business both locally and globally thereby eliminating a businesses limitations for revenue growth and expansion
        </MimaText>

        <div className={styles.set}>
          <Image
            src={HowToImage}
            alt="image showing a man and woman discussing"
            width={593}
            height={614}
            layout="fixed"
            ref={imageRef}
            className={`${styles.setImage} ${imageIsInView ? styles.fadeIn : ''}`}
          />
          <div className={styles.stepsCard}>
            <div className={styles.stepsCardTitle}>
              <MimaText variant='h4'>How to get started</MimaText>
              <MimaText variant="body">4 Steps to get your FashCreatives account</MimaText>
            </div>
            <div className={styles.stepsList}>
              {steps.map((step, index) => (
                <div className={index < steps.length - 1 ? styles.step : styles.lastStep} key={index}>
                  <div className={styles.title}>
                    <div className={styles.icon} ></div>
                    <MimaText variant='bodyBold' fontWeight={500} color='var(--color-dark2)'>{step.title}</MimaText>
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}><hr /></div>
                    <MimaText variant='small' color='var(--color-dark-3)' mt={0.8} mb={2.8}>{step.details} </MimaText>
                  </div>

                </div>

              ))}

            </div>
            <div className={styles.buttonSet}>
              <FashButton title="Start A Free Trial Now" icon={<FaArrowRight/>} color='var(--color-dark)' onClick={switchToSignup} />
              <MimaButton variant='outlined' title="Login" onClick={switchToLogin} width={18} height={5.8} />
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default HowTo;
