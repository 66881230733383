import constants from './constant';

// TypeScript interface to define the billing option structure
export interface BillingOptionProps {
  name: string;
  billingSubText: string;
  multiplier: number;
  discounter: string | null;
}

// Billing options array with type safety and corrected typos
export const billingOptions: BillingOptionProps[] = [
  {
      name: "annual",
      billingSubText: "annually",
      multiplier: 12,
      discounter: constants.DISCOUNTS.ONE_YEAR // Use constant
  },
  {
      name: "biannual",
      billingSubText: "biannually",
      multiplier: 6,
      discounter: constants.DISCOUNTS.SIX_MONTHS
  },
  {
      name: "quarterly", // Corrected typo
      billingSubText: "quarterly",
      multiplier: 3,
      discounter: constants.DISCOUNTS.THREE_MONTHS
  },
  {
      name: "monthly",
      billingSubText: "monthly",
      multiplier: 1,
      discounter: null // No discount for monthly
  }
];
