import { businessStore, userStore } from '../stores';
import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { Data, IControllerResponseDTO, ISubscriptionDTO } from '@/interfaces';

const getAllSubscriptions = async (planQuery: any): Promise<IControllerResponseDTO> => {
  const { status, data, errorMessage } = await apiRequest<Data>({
    url: `/subscriptions/plans?${planQuery}`,
    method: 'get',
  });
  const subData = (data || {}) as ISubscriptionDTO[];

  if (status === constant.Success) {
    businessStore.setSubscriptionPlans(subData);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const BusinessController = {
  getAllSubscriptions,
};

export default BusinessController;
